import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AddAttachmentModalComponent} from "../add-attachment-modal/add-attachment-modal.component";
import {Observable} from "rxjs/internal/Observable";
import {ActivatedRoute} from "@angular/router";
import {AttachmentContent} from "../../../../shared/interfaces";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-attachement-component',
  templateUrl: './attachement-component.component.html',
  styleUrls: ['./attachement-component.component.css']
})
export class AttachementComponentComponent implements OnInit {
  @Input() createWebCandidateUrlType!: (webCandidateId: number, url: string, typeId: number) => Observable<any>;
  @Input() createWebCandidateFileType!: (webCandidateId: number, typeId: number, docName: string, file: File) => Observable<any>;
  @Input() getAllDocumentOfWebCandidate!: (webCandidateId: number, page: number, size: number, active: boolean) => Observable<any>;
  page: number = 0;
  size: number = 4;
  totalElements: number = 0;
  totalPages: number = 0;
  attachmentData: AttachmentContent[] = [];
  isActive: boolean = true;
  @Input() deleteWebCandidateDocumentObservable!: (webCandidateId: number, documentId: number) => Observable<any>;
  @Input() inactiveWebCandidateDocumentObservable!: (webCandidateId: number, documentId: number) => Observable<any>;
  @Input() restoreWebCandidateDocumentObservable!: (webCandidateId: number, documentId: number) => Observable<any>;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive)
  }

  handlePage(e: any) {
    this.page = e.pageIndex;
    this.size = e.pageSize;
    this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive);
  }

  openAttachmentModal(value: string) {
    const dialogRef = this.dialog.open(AddAttachmentModalComponent, {
      width: '600px',
      data: {
        value,
        createWebCandidateFileType: this.createWebCandidateFileType,
        createWebCandidateUrlType: this.createWebCandidateUrlType,
        getAllDocumentOfWebCandidate: this.getAllDocumentOfWebCandidate
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive);
    })
  }

  getAllDocuments(webCandidateId: number, page: number, size: number, active: boolean) {
    this.getAllDocumentOfWebCandidate(webCandidateId, page, size, active).subscribe(
      (response) => {
        console.log('Response received:', response);
        this.page = response.page?.number;
        this.size = response.page?.size;
        this.totalElements = response.page?.totalElements;
        this.totalPages = response.page?.totalPages;
        this.attachmentData = response.content;
      },
      (error) => {
        console.error('Error fetching documents:', error);
      }
    );
  }

  deleteConfirmation(action: string, documentId: number, isArchive: boolean) {
    const webCandidateId = Number(this.route.snapshot.params['id']);
    if (isArchive) {
      this.inactiveWebCandidateDocumentObservable(webCandidateId, documentId).subscribe(
        (res) => {
          this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive);
        },
        (error) => {
          console.error('Error archiving document:', error);
        }
      );
    }
  }
  deleteDocument(action: string, documentId: number, isArchive: boolean) {
    const webCandidateId = Number(this.route.snapshot.params['id']);
  {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure you want to delete this document?',
          type: 'Delete'
        }
      });
      dialogRef.beforeClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.deleteWebCandidateDocumentObservable(webCandidateId, documentId).subscribe(
            (res) => {
              this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive);
            },
            (error) => {
              console.error('Error deleting document:', error);
            }
          );
        }
      });
    }
  }

  restoreTrashedDocument(documentId: number) {
    const webCandidateId = Number(this.route.snapshot.params['id']);
    this.restoreWebCandidateDocumentObservable(webCandidateId, documentId).subscribe(
      (res) => {
        this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive)
      },
      (error) => {
        console.error('Error restoring document:', error);
      }
    );
  }

  toggleActiveWebCandidate($event: MatSlideToggleChange) {
    this.isActive = !$event.checked;
    this.getAllDocuments(Number(this.route.snapshot.params['id']), this.page, this.size, this.isActive);
  }

  goToLink(url: string) {
    window.open(url, " ");
  }

}
