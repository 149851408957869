import {Component, Input, OnInit} from '@angular/core';
import {Candidate} from "../../../../shared/interfaces";
import {DatePipe} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-common-head-component',
  templateUrl: './common-head-component.component.html',
  styleUrls: ['./common-head-component.component.css']
})
export class CommonHeadComponentComponent implements OnInit {
  @Input() customerInfoData: Candidate;
  currentRoute: string = "";

  constructor(
    private date : DatePipe,
    private route : ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    if(this.route?.snapshot?.url[1]?.path === 'candidate') {
      this.currentRoute = 'candidate'
    } else if(this.route?.snapshot?.url[1]?.path === 'customer') {
      this.currentRoute = 'customer'
    }
  }

}
