<div class="content-wrapper">
  <div class="content-wrapper-main-inner-content">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="ps-3" style="font-weight:500; font-size: 20px">Attachments</h6>
      <div class="dropdown pe-3 d-flex align-items-center justify-content-center">
        <div class="d-flex">
          <div class="ActiveButton pe-2">Show Archived</div>
          <mat-slide-toggle class="example-margin pe-3" color="primary"
                            [disabled]="false" [checked]="false"
                            (change)="toggleActiveWebCandidate($event)">
          </mat-slide-toggle>
        </div>
        <button class="btn theme-border-color dropdownButton" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">+ Add Attachment
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="openAttachmentModal('Link')">Attach Link</a>
          <a class="dropdown-item" (click)="openAttachmentModal('Document')">Attach Document</a>
        </div>
      </div>
    </div>
    <div class="p-3">
      <table class="table" *ngIf="attachmentData.length > 0">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of attachmentData">
          <td>{{item?.documentType?.name ?? '--'}}</td>
          <td>{{ item?.attachmentLabelType === 'LABEL' ? 'DOCUMENT' : (item?.attachmentLabelType ?? '--') }}</td>
          <td>
           <div class="d-flex align-items-center justify-content-start">
             <div>
               <span (click)="goToLink(item.url)"><a style="color: blue; text-decoration: underline;cursor: pointer" >View</a></span>
           </div>
             <div class="ms-5">
               <button id="more-btn dropdownMenuButton1" class="more-btn" data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false"
                       type="button">
                 <span class="more-dot"></span>
                 <span class="more-dot"></span>
                 <span class="more-dot"></span>
               </button>
               <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                 <li>
                   <a *ngIf="item.active" class="dropdown-item cursor-p"
                      (click)="deleteConfirmation('Archive',item.id,item.active)">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                       <path
                               d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                               fill="#ED0F0F"/>
                     </svg>
                     Archive
                   </a>
                   <a *ngIf="!item.active" class="dropdown-item" (click)="restoreTrashedDocument(item.id)">
                     <i class='fas fa-trash-restore text-success mx-1'></i>Restore
                   </a>
                   <a class="dropdown-item cursor-p"
                      (click)="deleteDocument('Delete',item.id,item.active)">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                       <path
                               d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                               fill="#ED0F0F"/>
                     </svg>
                     Delete Permanent
                   </a>
                 </li>
               </ul>
             </div>

           </div>
          </td>
        </tr>
        </tbody>
      </table>
      <mat-paginator
        [length]="totalElements"
        [pageSize]="size"
        [hidden]="totalElements <= 4"
        (page)="handlePage($event)"
        [pageSizeOptions]="[4,8,12,25,50,100]"
      >
      </mat-paginator>
      <div  *ngIf="attachmentData.length === 0">
        <div class="d-flex align-items-center justify-content-center">
          Document Not Found
        </div>
      </div>
    </div>
  </div>
</div>
