<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Consultants </h5>
        <div class="d-flex">
          <div class="me-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="downloadReport()">
              <i class="fas fa-cloud-download-alt"></i> Download Report
            </button>
          </div>
          <div class="page-title-right me-3">
            <a (click)="openDialogs('new',null)" class="btn btn-create theme-btn-color"> <i
              class="fas fa-plus fa-xs me-2"></i>
              Create New Consultant
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="d-flex justify-content-end dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" class="form-control" placeholder="Search Consultants"
                     (keyup)="getAllConsultants(this.currentPage,this.pageSize, '', this.search, this.showSearchEnable, this.showActive)"
                     (search)="getAllConsultants(this.currentPage,this.pageSize, '', this.search, this.showSearchEnable, this.showActive)"
                     type="search"
                     aria-controls="data-table">
              <button class="search-button"
                      (click)="this.getAllConsultants(this.currentPage,this.pageSize, '', this.search, this.showSearchEnable, this.showActive)"
                      type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>

        <div class="col-md-8 entry-label d-flex justify-content-end align-items-center">
        <span class="mx-4">
          <mat-slide-toggle
            class="example-margin "
            color="primary"
            [disabled]=false
            [checked]="showFeatured"
            (change)="toggleFeaturedConsultant($event)">
            <div class="ActiveButton">Show Featured </div>
          </mat-slide-toggle>
        </span>
          <span class="mx-4">
          <mat-slide-toggle
            class="example-margin "
            color="primary"
            [disabled]=false
            [checked]="showSearchEnable"
            (change)="toggleSearchEnableConsultant($event)">
          <div class="ActiveButton">Show Search Enable</div>
        </mat-slide-toggle>
        </span>
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="showActive"
            (change)="toggleActiveConsultant($event)">
            <div class="ActiveButton">Show Active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
      </div>
      <!--      <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">-->
      <!--        <mat-spinner diameter="50"></mat-spinner>-->
      <!--      </div>-->
      <!--      <div *ngIf="!showLoader">-->
      <div>
        <table class="table table-striped ">
          <thead class="tableview theme-bg-color">
          <tr>
            <th>Consultant ID</th>
            <th>Consultant Name</th>
            <th>Title</th>
            <th>Experience</th>
            <th>Vendor Name</th>
            <th>Primary Skills</th>
            <th>Consultant Status</th>
            <th>Location Preference</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>

          <ng-container>
            <tr>
              <td>
                <input
                  class="w-100 customInput"
                  type="search"
                  placeholder="Search"
                  [(ngModel)]="consultantId"
                  (ngModelChange)="onInputChange($event,'consultantId')"
                >
              </td>
              <td>
                <input
                  class="w-100 customInput"
                  type="search"
                  placeholder="Search"
                  [(ngModel)]="consultantName"
                  (ngModelChange)="onInputChange($event,'consultantName')"
                >
              </td>
              <td>
                <input
                  class="w-100 customInput"
                  type="search"
                  placeholder="Search"
                  [(ngModel)]="title"
                  (ngModelChange)="onInputChange($event,'title')"
                >
              </td>
              <td>
                <div class="dropdown" id="dropdownExperience" #dropdownExperience>
                  <button class="experienceButton dropdown-toggle" type="button"
                          (click)="toggleDropdown('dropdownExperience')"
                          style="font-size: 12px; color: rgba(0,0,0,0.5)">
                    Experience({{ selectedItemsExperience.length }})
                  </button>
                  <div class="dropdown-menu">
                    <div
                      *ngFor="let option of experienceOptions"
                      class="dropdown-item"
                      [class.selected]="isSelectedExperience(option, selectedItemsExperience)"
                      (click)="toggleSelectionExperience(option, selectedItemsExperience)">
                      {{ option.label }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <input
                  class="w-100 customInput"
                  type="search"
                  placeholder="Search"
                  [(ngModel)]="vendorsName"
                  (ngModelChange)="onInputChange($event,'vendorsName')">
              </td>
              <td>
                <div class="dropdown" id="dropdownSkills" #dropdownSkills>
                  <button class="experienceButton dropdown-toggle" type="button"
                          style="font-size: 12px; color: rgba(0,0,0,0.5)"
                          (click)="toggleDropdown('dropdownSkills')">
                    Skills({{ selectedSkill.length }})
                  </button>
                  <div class="dropdown-menu">
                    <mat-form-field appearance="outline" class="customMultiSelect">
                      <mat-chip-list #chipList aria-label="skill selection">
                        <mat-chip
                          class="matChip"
                          *ngFor="let skill of selectedSkill"
                          (removed)="removeSkill(skill)">
                          {{ skill.title }}
                          <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
                        </mat-chip>
                        <input #skillsAutoCompleter
                               placeholder="skills..."
                               #chipGrid
                               [formControl]="skillControl"
                               [matAutocomplete]="auto"
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               #autoTrigger='matAutocompleteTrigger'
                               (click)="openAutocomplete(autoTrigger)"
                        >
                      </mat-chip-list>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
                        <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                                    [disabled]="disableSkill(skill)">
                          {{ skill.title }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td>
                <div class="dropdown" id="dropdownConsultantStatus" #dropdownConsultantStatus>
                  <button class="experienceButton dropdown-toggle" type="button"
                          style="font-size: 12px; color: rgba(0,0,0,0.5)"
                          (click)="toggleDropdown('dropdownConsultantStatus')">
                    Status({{ selectedItemsConsultantStatus.length }})
                  </button>
                  <div class="dropdown-menu">
                    <div
                      *ngFor="let option of consultantStatusOptions"
                      class="dropdown-item"
                      [class.selected]="isSelected(option, selectedItemsConsultantStatus)"
                      (click)="toggleSelection(option, selectedItemsConsultantStatus)">
                      {{ option }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="dropdown" id="dropdownLocationPreference" #dropdownLocationPreference>
                  <button class="experienceButton dropdown-toggle" type="button"
                          style="font-size: 12px; color: rgba(0,0,0,0.5)"
                          (click)="toggleDropdown('dropdownLocationPreference')">
                    Location({{ selectedItemsLocationPreference.length }})
                  </button>
                  <div class="dropdown-menu">
                    <div
                      *ngFor="let option of locationPreferenceOptions"
                      class="dropdown-item"
                      [class.selected]="isSelected(option, selectedItemsLocationPreference)"
                      (click)="toggleSelection(option, selectedItemsLocationPreference)">
                      {{ option }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="dropdown" id="dropdownDate" #dropdownDate>
                  <button class="experienceButton dropdown-toggle" type="button"
                          style="font-size: 12px; color: rgba(0,0,0,0.5);padding:0 20px;"
                          (click)="toggleDropdown('dropdownDate')">
                    <svg class="me-1" fill="#000000" width="15px" height="15px" viewBox="0 0 256 256" id="Flat"
                         xmlns="http://www.w3.org/2000/svg"
                         stroke="#000000" stroke-width="0.00256">
                      <g id="SVGRepo_bgCarrier" stroke-width="0">
                      </g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M112.001,229.05469A12.00583,12.00583,0,0,1,100,217.05176v-77.959a3.98926,3.98926,0,0,0-1.03955-2.68945l-65.75537-72.331A12,12,0,0,1,42.08447,44H213.91553a12,12,0,0,1,8.87939,20.07227l-65.75439,72.33007A3.98975,3.98975,0,0,0,156,139.09277v56.626a11.97406,11.97406,0,0,1-5.34424,9.98437l-31.99951,21.333A11.98627,11.98627,0,0,1,112.001,229.05469ZM42.08447,52A4.00015,4.00015,0,0,0,39.125,58.69141l65.75439,72.33007A11.971,11.971,0,0,1,108,139.09277v77.959a3.99924,3.99924,0,0,0,6.21826,3.32812l32.00049-21.333A3.99,3.99,0,0,0,148,195.71875v-56.626a11.97153,11.97153,0,0,1,3.12158-8.07226L216.875,58.69141A4.00015,4.00015,0,0,0,213.91553,52Z">
                        </path>
                      </g>
                    </svg>
                    Duration
                  </button>
                  <div class="dropdown-menu">
                    <div class="form-check form-switch" style="left:27px">
                      <input class="form-check-input" type="checkbox" role="switch"
                             id="flexSwitchCheckDefault" (change)="handleDateToggleChange($event)">
                      <label class="form-check-label" for="flexSwitchCheckDefault">
                        <div style="font-size: 13px">{{ !isCreatedDate ? 'Created Date' : 'Updated Date' }}</div>
                      </label>
                    </div>
                    <div class="input-group m-2" style="width: 85%">
                      <input
                        class="w-100 customInput"
                        [matDatepicker]="picker"
                        style="position: relative; height: 40px"
                        placeholder="From"
                        [(ngModel)]="selectedFromDate"
                        (dateChange)="onFromDateChange($event)"
                        (ngModelChange)="updateEndDateMinDate()"
                      >
                      <mat-datepicker #picker></mat-datepicker>
                      <div
                        style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); background-color: white">
                      <span #picker (click)="picker.open()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                             viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                             transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
                        </g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                             stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                            <path
                              d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                            </path>
                          </g>
                        </svg>
                      </span>
                      </div>
                    </div>

                    <div class="input-group m-2" style="width: 85%">
                      <input
                        class="w-100 customInput"
                        [matDatepicker]="picker1"
                        style="position: relative; height: 40px"
                        placeholder="To"
                        [(ngModel)]="selectedToDate"
                        (dateChange)="onToDateChange($event)"
                        [disabled]="!selectedFromDate"
                        [min]="minEndDate"
                      >
                      <mat-datepicker #picker1></mat-datepicker>
                      <div
                        style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%);  background-color: white">
                      <span (click)="picker1.open()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                             viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                             transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
                        </g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                             stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                            <path
                              d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                            </path>
                          </g>
                        </svg>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngFor="let consultant of consultants">
            <tr>
              <td style="width: 13%">
                <span style="cursor: pointer" class="theme-text-color fw-bold cursor-p" title="Edit "
                      (click)="openDialogs('edit',consultant)">{{ consultant.consultantId }}
              </span>
              </td>
              <td
                style="width: 13%">{{ consultant.firstName + ' ' + (consultant?.middleName == null ? '' : consultant?.middleName) + ' ' + (consultant.lastName == null ? '' : consultant.lastName) }}
              </td>
              <!--              <td style="width: 13%">{{ consultant.title != '' ? consultant.title ?? '&#45;&#45;' : '&#45;&#45;' }}</td>-->
              <td style="width:13%">
                {{ consultant.title ? consultant.title : '' }}
              </td>
              <td style="width: 13%">
                {{
                  (consultant.experienceYear || consultant.experienceMonth) ?
                    ((consultant.experienceYear != 0) ? (consultant.experienceYear + ' yrs') : '' || '') + " " + (((consultant.experienceMonth != 0) ? (consultant.experienceMonth + ' months') : '') || '') :
                    '--'
                }}
              </td>
              <td style="width: 13%">
                {{ consultant.vendor != null ? consultant.vendor.vendorName ?? '--' : '--' }}
              </td>

              <td style="width: 13%">
                <div *ngIf="consultant.primarySkills!=null">
                  <div>
                    {{ this.getSkills((consultant.primarySkills.concat(consultant.secondarySkills ?? [])))['skillsString'] }}
                    <span
                      matTooltip="{{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.length > 0
                                      ? (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.join(', ')
                                      : (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).skillsString }}"
                      matTooltipPosition="before"
                      matTooltipClass="custom-tooltip"
                      *ngIf="(this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkillsCount > 0"
                      class="badge badge-pill badge-light">

                      <i class="fas fa-plus fa-xs"></i>
                      <span class="pt-1" style="font-size: 11px">
                        {{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkillsCount }}
                      </span>
                    </span>
                  </div>
                </div>
                <div *ngIf="consultant.primarySkills?.length==0 && consultant.secondarySkills?.length==0 ">
                  --
                </div>
              </td>
              <td style="width: 10%">
                <label class="switch"
                       style="color: #14804A; font-weight: 500;background-color: #E1FCEF">{{ consultant.status ?? '--' }}</label>
              </td>
              <td>
                <div class="d-flex flex-row" matTooltip="{{formatLocationData(consultant?.preferredLocations)}}"
                     matTooltipPosition="before"
                     matTooltipClass="custom-tooltip"
                >
                  <div *ngIf="consultant.jobType" class="p-0 m-0"> {{ consultant.jobType }}</div>
                  <div *ngIf="consultant.preferredLocations.length > 0 && consultant.jobType"
                       class="me-1 ms-1">{{ '|' }}
                  </div>
                  <div *ngIf="consultant.preferredLocations.length > 0 && consultant.jobType"
                       class="p-0 m-0 d-flex flex-row">
                    {{
                      consultant.preferredLocations[0].name.length > 8 ? consultant.preferredLocations[0].name.substring(0, 8) : consultant.preferredLocations[0].name
                    }}
                    <div
                      *ngIf="consultant.preferredLocations.length > 1 || consultant.preferredLocations[0].name.length > 8">
                      {{
                        '...'
                      }}
                    </div>
                  </div>
                </div>
              </td>

              <td style="width: 13%">
                <div class="d-flex flex-column">
                  <span *ngIf="consultant.updatedDate" style="font-size: 13px;">
                  Updated On {{ consultant.updatedDate | date: 'dd MMM y hh:mm a' }}
                </span>
                  <span class="p-0 m-0" *ngIf="consultant.createdDate" style="font-size: 13px;">
                      Created On {{ consultant.createdDate | date: 'dd MMM y hh:mm a' }}
                </span>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="!showLoader && consultants.length == 0" class="d-flex justify-content-center mt-8">
        <div>
          <img src="assets/images.png" alt=""><br>
          <h4> No Result Found</h4>
        </div>
      </div>
      <mat-paginator [length]="totalEmployee"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[10,12,25,50,100]"
                     [hidden]="totalEmployee == 0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
