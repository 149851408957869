import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {Candidate} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {CreateCustomersComponent} from "../../manage-customers/create-customers/create-customers.component";
import {DataService} from "../../../../services/data.service";
import {CustomerContactDialogComponent} from "../customer-contact-dialog/customer-contact-dialog.component";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  statusForm: FormGroup;
  @Input() customerInfoData!: Candidate;
  loading: boolean = false
  currentRoute: string = '';
  candidateData: any;
  @Input() verifyWebCustomer!: (webCandidateId: number, verificationStatus: string) => Observable<any>;
  formGroup: FormGroup;
  verificationStatus: string | null = null;
  isDisabled: boolean = true;

  constructor(
    private toastr: ToastrService,
    private service: ApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.formGroup = this.fb.group({
      verificationStatus: ['']
    });
  }

  ngOnInit(): void {
    const webCandidateId = this.route?.snapshot?.params['id'];
    this.statusForm = new FormGroup({
      status: new FormControl('', Validators.required),
    });
    if (this.route?.snapshot?.url[1]?.path === 'candidate') {
      this.currentRoute = 'candidate'
    } else if (this.route?.snapshot?.url[1]?.path === 'customer') {
      this.currentRoute = 'customer'
      this.getSingleWebClient(webCandidateId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerInfoData'] && changes['customerInfoData'].currentValue) {
      const initialStatus = this.customerInfoData.status || 'PENDING';
      this.statusForm.patchValue({ status: initialStatus });
      this.isDisabled = initialStatus !== 'SHORTLISTED';
    }
  }

  onSubmit() {
    this.loading = true
    if (this.statusForm.valid) {
      const selectedStatus = this.statusForm.value.status;
      this.service.changeStatusOfWebCandidate(this.route.snapshot.params['id'], {status: selectedStatus}).subscribe((res) => {
        if (selectedStatus === 'SHORTLISTED') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
        this.loading = false
        this.toastr.success('Status updated successfully', 'Success');
      }, (err) => {
        this.toastr.error('Failed to updated', 'Error');
        this.loading = false
      })
    }
  }

  upgradeToConsultant() {
    const dialogRef = this.dialog.open(DeletePopupComponent, {
      width: '300px',
      data: {
        message: 'Are you sure you want to upgrade this candidate to Consultant?',
        type: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.upgradeWebCandidate(this.route.snapshot.params['id']).subscribe(
          (res) => {
            this.toastr.success('Candidate upgraded to Consultant successfully.');
            this.router.navigate(['/dashboard/web_data'], {queryParams: {tab: 0}});
          },
          (err) => {
            if (err.status === 400) {
              this.toastr.error('Upgrade failed. Invalid candidate data.', 'Error');
            } else if (err.status === 500) {
              this.toastr.error('Server error during upgrade. Please try again later.', 'Error');
            } else {
              this.toastr.error('An unexpected error occurred during the upgrade.', 'Error');
            }
          }
        );
      }
    });
  }

  verifyWebClient(): void {
    const currentStatus = this.formGroup.get('verificationStatus')?.value;
    if (currentStatus) {
      this.verifyWebCustomer(this.route.snapshot.params['id'], currentStatus)
        .subscribe({
          next: (res) => {
            this.verificationStatus = currentStatus;
            this.toastr.success('Candidate Status Updated!', 'Success');
          },
          error: (err) => {
          }
        });
    }
  }

  getSingleWebClient(webCandidateId: number): void {
    this.service.getWebClient(webCandidateId).subscribe((res: any) => {
      this.candidateData = res;
      const status = res.verificationStatus === 'INPROGRESS' ? 'VERIFIED' : res.verificationStatus;
      this.verificationStatus = status;
      this.formGroup.patchValue({
        verificationStatus: status
      });
    });
  }

  openCustomerContactDialog() {
    const dialogRef = this.dialog.open(CustomerContactDialogComponent, {
      width: '400px',
      data: {
        id: this.route.snapshot.params['id']
      },
    });
  }

}
