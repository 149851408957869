import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-communication-component',
  templateUrl: './communication-component.component.html',
  styleUrls: ['./communication-component.component.css']
})
export class CommunicationComponentComponent implements OnInit, AfterViewInit {
  @Input() addWebCandidateNotesObservable!: (webCandidateId: number, payload: {
    description: string
  }) => Observable<any>;
  @Input() updateWebCandidateNotesObservable!: (webCandidateId: number, communicationId: number, payload: {
    description: string
  }) => Observable<any>;
  @Input() deleteWebCandidateNotesObservable!: (webCandidateId: number, communicationId: number) => Observable<any>;
  @Input() getAllWebCandidateNotesObservable!: (webCandidateId: number) => Observable<any>;
  webCandidateId: number
  notesForm: FormGroup;
  notesData: any[] = []
  editableNoteId: any;
  loading: boolean;
  currentRoute: string = "";

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.webCandidateId = this.route.snapshot.params['id']
    this.notesForm = this.fb.group({
      description: "",
      editDescription: ""
    })
    this.onGetAllNotes(this.webCandidateId);
  }

  ngAfterViewInit() {
    // this.onGetAllNotes(this.webCandidateId);
  }

  onAddNote(webCandidateId: number, payload: { description: string }): void {
    this.loading = true
    this.addWebCandidateNotesObservable(this.route.snapshot.params['id'], payload).subscribe(
      (response) => {
        this.notesForm.reset();
        this.onGetAllNotes(this.webCandidateId);
        this.loading = false
      },
      (error) => {
        console.error('Error adding note:', error)
        this.loading = false;
      }
    );
  }

  onUpdateNote(webCandidateId: number, communicationId: number, payload: { description: string }): void {
    this.updateWebCandidateNotesObservable(this.route.snapshot.params['id'], communicationId, payload).subscribe(
      (response) => {
        this.onGetAllNotes(this.webCandidateId);
        this.editableNoteId = null;
      },
      (error) => console.error('Error updating note:', error)
    );
  }

  onDeleteNote(webCandidateId: number, communicationId: number): void {
    this.dialog.open(DeletePopupComponent, {
      data: {
        message: 'Are you sure you want to delete this Note?',
        type: 'Delete'
      }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.deleteWebCandidateNotesObservable(this.route.snapshot.params['id'], communicationId).subscribe(
          (response) => {
            this.onGetAllNotes(this.webCandidateId);
          },
          (error) => {
            console.error('Error deleting note:', error);
          }
        );
      }
    });
  }

  onGetAllNotes(webCandidateId: number): void {
    this.getAllWebCandidateNotesObservable(this.route.snapshot.params['id']).subscribe(
      (response) => {
        this.notesData = response
      },
      (error) => console.error('Error fetching notes:', error)
    );
  }

  onSubmit() {
    if (this.notesForm && this.notesForm.value.description.trim()) {
      this.onAddNote(this.webCandidateId, {description: this.notesForm.value.description})
    } else {
      this.toastr.error('The notes cant be empty!');
    }
  }

  editNotes(note: any) {
    this.editableNoteId = note.id;
    this.notesForm.patchValue({
      editDescription: note.description
    });
  }

}
