import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Candidate, FormUpdateEvent, Skill, VendorCorp} from "../../../../shared/interfaces";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {map, startWith} from "rxjs/operators";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-skills-component',
  templateUrl: './skills-component.component.html',
  styleUrls: ['./skills-component.component.css']
})
export class SkillsComponentComponent implements OnInit, AfterViewInit {
  @Input() customerInfoData: Candidate;
  skillControl = new FormControl('');
  selectedSkill: Skill[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  skillName = '';
  filteredSkills: Observable<Skill[]>;
  skills: any[] = [];
  @ViewChild("chipGrid") chipGrid: ElementRef;
  vendor: VendorCorp = {} as any;
  skillsRes: Skill[] = [];
  showloader = false;
  role: string | null;
  type = '';
  visible2 = false;
  @ViewChild('skillsAutoCompleter', {read: MatAutocompleteTrigger})
  skillsAutoCompleter: MatAutocompleteTrigger;
  @Input() vendorData: any;
  @Input() openDialogType: string;
  loading: boolean = false
  disabledSkills: Skill[] = [];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  @Output() updateSkill = new EventEmitter<{ skills: any[] }>();
  exactMatchFound: boolean = false;
  @Input() handleSkillUpdate!: (jobCandidateId: number, payload: { skills: number[] }) => Observable<any>;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getSkillType();
    this.role = localStorage.getItem('role');
    this.previousFormValue = {
      skills: [],
    };

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.customerInfoData && this.customerInfoData.skills.primarySkills) {
        this.skills = this.customerInfoData.skills.primarySkills ?? []
      }
    }, 1000)
  }


  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  disableSkill(skill: any): boolean {
    return this.selectedSkill.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  removeSkill(skill: Skill): void {
    const index = this.selectedSkill.findIndex(s => s.id === skill.id);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
    }
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({...skill, disabled: true});
  }

  addSkillType(): void {
    if (this.skillName === "") {
      return
    } else {
      let payload = {
        'title': this.skillName,
      }
      this.service.addOrgSkills(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getSkillType();
        this.selectedSkill.push(res);
        this.skillName = '';
        this.chipGrid.nativeElement.value = '';
        this.skillControl.setValue('');
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }

  addSkill(): void {
    this.loading = true;
    const allSkills = [...this.selectedSkill, ...this.skills];
    const skillsIds = allSkills.map((res) => res.id);
    this.handleSkillUpdate(this.customerInfoData.id, {skills: skillsIds}).subscribe(
      (res: any) => {
        this.loading = false;
        this.skillName = '';
        this.skills = [...this.skills, ...this.selectedSkill];
        this.selectedSkill = [];
        this.previousFormValue = {skills: []};
        this.showloader = false;
        this.getSkillType();
      },
      (error: any) => {
        console.log('Error:', error.message);
        this.loading = false;
      }
    );
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFound = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);
    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }

  openDialogSkillDelete(skillId: number, index: number, skillName: string): void {
    const dialogRef1 = this.dialog.open(PopUpComponent, {
      data: {
        message: `Are you sure you want to delete the "${skillName}" skill?`,
        type: 'confirmation'
      }
    });
    dialogRef1.afterClosed().subscribe((res) => {
      if (res) {
        this.service.deleteCandidateSkill(Number(this.customerInfoData.id), skillId).subscribe({
          next: () => {
            this.skills.splice(index, 1);
            this.openSnackBar('Successfully Deleted', ' ');
          },
          error: (error) => {
            console.error('Failed to delete skill:', error);
            this.openSnackBar('Failed to delete skill', 'Error');
          }
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
