import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DocsType, DocType } from '../../../../shared/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachementComponentComponent } from '../attachement-component/attachement-component.component';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-add-attachment-modal',
  templateUrl: './add-attachment-modal.component.html',
  styleUrls: ['./add-attachment-modal.component.css']
})
export class AddAttachmentModalComponent implements OnInit {
  attachmentForm: FormGroup;
  docControl = new FormControl('');
  urlType: string = '';
  filteredDocs: Observable<any[]>;
  typeId: string = '';
  docTypeName: string = '';
  typeRes: DocsType[] = [];
  imageSrc!: string;
  file: File | null = null;
  logoName: string;
  loading: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {
      value: string,
      createWebCandidateFileType: (webCandidateId: number, typeId: number, docName: string,file:File) => Observable<any>,
      createWebCandidateUrlType: (webCandidateId: number, url: string,typeId:number) => Observable<any>,
      getAllDocumentOfWebCandidate: (webCandidateId: number,page:number,size:number) => Observable<any>
    },
    private dialogRef: MatDialogRef<AttachementComponentComponent>,
    private route:ActivatedRoute
  ) {
    this.attachmentForm = this.fb.group({
      url: ['']
    });
  }

  ngOnInit(): void {
    this.getDocType();
  }

  getDocType() {
    this.service.getDocumentType().then(response => {
      this.typeRes = [];
      response.map((e: DocType) => {
        if (e.active === true) {
          this.typeRes.push(e);
        }
      });
      this.filteredDocs = this.docControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value.trim() : value?.name.trim() ?? '';
          return name ? this._docsFilter(name as string) : this.typeRes.slice();
        })
      );
      this.typeRes.push({ name: 'others', id: 0, seq: '0', active: true });
    });
  }

  _docsFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.typeRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  chooseDocType(data: any): void {
    const _type = data?.source?.value?.id;
    if (data.isUserInput) {
      this.typeId = _type;
      this.docTypeName = data.source.value?.name;
    } else {
      return;
    }
  }

  addDocType(event: any, type: string, docTypeName: string): void {
    if (type === 'doc') {
      const payload = { name: docTypeName };
      this.service.addOrgDocType(payload)
        .then(res => {
          this.getDocType();
          this.typeId = res?.id;
          this.displayDocFn(res);
          this.toastr.success('Document type added successfully', 'Success');
        })
        .catch(e => {
          this.toastr.error('Failed to add document type.', 'Error');
        });
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    if (dragEvent) {
      this.file = event.dataTransfer.files[0];
    } else {
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
      } else {
        this.file = null;
        return;
      }
    }
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  addFile(webCandidateId: number, typeId: number, docName: string,file:File):Observable<any> {
    return  this.data.createWebCandidateFileType(webCandidateId, typeId, docName,file)
  }

  addUrl(webCandidateId: number, url: string,typeId:number) {
    return this.data.createWebCandidateUrlType(webCandidateId, url, typeId)
  }

  delete(): void {
    this.logoName = '';
    this.file = null;
  }

  onClose(): void {
    this.dialogRef.close('CloseButtonClicked');
  }

  onSubmit(): void {
    if (this.attachmentForm.valid) {
      this.loading = true;
      const formData = this.attachmentForm.value;
      const webCandidateId = Number(this.route.snapshot.params['id']);

      if (this.data.value === 'Document') {
        if (this.file) {
          const docName = this.file?.name ?? 'docName';
          this.addFile(webCandidateId, Number(this.typeId), docName, this.file).subscribe({
            next: (res) => {
              this.toastr.success('File uploaded successfully', 'Success');
              this.dialogRef.close();
            },
            error: (error) => {
              this.toastr.error('Error uploading file.', 'Error');
              this.loading = false;
              console.error(error);
            },
            complete: () => {
              this.loading = false;
            }
          });
        } else {
          this.toastr.error('Please select a file to upload.', 'Error');
          this.loading = false;
        }
      } else if (this.data.value === 'Link') {
        if (formData.url) {
          this.addUrl(webCandidateId, formData.url, Number(this.typeId)).subscribe({
            next: (res) => {
              this.toastr.success('URL added successfully', 'Success');
              this.dialogRef.close();
            },
            error: (error) => {
              this.toastr.error('Error adding URL.', 'Error');
              this.loading = false;
              console.error(error);
            },
            complete: () => {
              this.loading = false;
            }
          });
        } else {
          this.toastr.error('Please enter a valid URL.', 'Error');
          this.loading = false;
        }
      }
    } else {
      this.toastr.error('Please fill the required fields.');
    }
  }

}
