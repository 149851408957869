<div class="content-wrapper">
  <h4 class="">Vendor Search</h4>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12 vendor-search mb-3">
          <div class="d-flex justify-content-between">
            <h5>Vendor for search</h5>
            <div class="mb-3 p-0">
              <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
                <i class="fas fa-redo me-2"></i>
                Reset Filters
              </button>
            </div>
          </div>

          <div id='search-box'>
            <input [(ngModel)]="search" (ngModelChange)="updateSearch()" class="form-control" placeholder="Search for vendor’s name"
                   type="search" id='search-text' placeholder="Type vendor's name.." style="color: black">
            <button class="search-button" (click)="this.loadVendors(this.currentPage, this.pageSize,this.filters)" type='button'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                  fill="#383838"/>
              </svg>
            </button>
          </div>
        </div>

        <div class="col-md-12 vendor-search-table">
          <table class="table table-striped">
            <thead class="tableview theme-bg-color">
            <tr>
              <th>Vendor's name</th>
              <th>Location</th>
              <th>Skills</th>
            </tr>
            </thead>
            <tbody class="search-results">
            <tr search-filter
                [searchFields]="searchFieldName"
                [filters]="filters"
                (filtersChanged)="onFiltersChanged($event)"
                (resetFilters)="clearFilter()">
            </tr>
            <ng-container  *ngIf="vendors.length>0">
              <tr *ngFor="let vendor of vendors| paginate: {
                                                    itemsPerPage: 8,
                                                     currentPage: this.currentPage,
                                                     totalItems: this.length }  ;
                                                      let i=index" class="vendors-listing mb-3 position-relative">
                <td class="col-md">
                  <div (click)="openDialog(this.role == 'ADMIN' ? 'edit':'readOnly',vendor)"
                       class="theme-text-color fw-bold text-capitalize" style="cursor: pointer">{{ vendor.vendorName }}
                  </div>
                </td>
                <td class="col-md" style="font-weight: 400">
                  {{ (vendor.address.city?.name == "" || vendor.address.city?.name == null) ? "" : vendor.address.city?.name + ',' }}
                  {{ (vendor.address.state?.name == "" || vendor.address.state?.name == null) ? "" : vendor.address.state?.name + ', ' }}
                  {{ (vendor.address.country?.name == "" || vendor.address.country?.name == null) ? "--" : vendor.address.country?.name }}
                </td>
                <td class="col-md">
                  <span *ngFor="let skill of vendor?.skills ; let i=index">{{ skill?.title + ', ' }}</span>
                  <span *ngIf="vendor.skills.length<=0">--</span>
                </td>
              </tr>
            </ng-container>

            </tbody>
          </table>

        </div>
        <mat-paginator [length]="totalVendors"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10,12,25,50,100]"
                       [hidden]="totalVendors==0"
                       aria-label="Select page">
        </mat-paginator>
        <div *ngIf="vendors.length<=0 "
             class="no-results d-flex flex-column align-items-center vendor-search-table">
          <img src="../../assets/illustration-empty-results.svg" width="250">
          <h4>No vendors matches your requirements</h4>
          <p>Take a another pass with a different search term</p>
        </div>

      </div>
<!--      <div class="col-md-4 filter-section">-->
<!--        <app-filter (selectedFilters)="applyFilter($event)"></app-filter>-->
<!--      </div>-->
    </div>
  </div>

<!--  <div *ngIf="loading">-->
<!--    <div *ngFor="let i of [].constructor(pageSize)">-->
<!--      <div class="shimmer-loader mb-5">-->
<!--        <div class="row">-->
<!--          <div class="col-md-2">-->
<!--            <div class="picture shine"></div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="lines shine"></div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="lines shine"></div>-->
<!--          </div>-->
<!--          <div class="col-md-4">-->
<!--            <div class="lines shine"></div>-->
<!--          </div>-->
<!--          <div class="col">-->
<!--            <div class="button shine"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
