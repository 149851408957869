<div>
  <div style="margin-left:30px" class="pt-4 pb-2">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h6 class="page_head">Solutions</h6>
      </div>
      <div class="me-3">
        <a [routerLink]="['/dashboard/create-solutions']" class="btn btn-create text-white btn-sm theme-btn-color">
          Create Solution
          <i class="fas fa-plus fa-xs me-2"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="col-md-12">
      <div class="content-wrapper-main-inner-content">
        <div class="row mb-2">
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center">
              <div class="search-input-wrapper col-4">
                <label class="w-100 search-input">
                  <input [(ngModel)]="search" (ngModelChange)="updateSearch()" #input class="form-control"
                         placeholder="Search Tasks"
                         type="search" aria-controls="data-table">
                  <button class="search-button" type='button'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                            fill="#808080"/>
                    </svg>
                  </button>
                </label>
              </div>

              <div class="d-flex justify-content-between align-items-center">
               <div class="pe-5">
                 <mat-slide-toggle
                   class="example-margin pe-3"
                   color="primary"
                   [disabled]=false
                   [checked]="true"
                   (change)="toggleSearchEnableSolution($event)">
                   <div class="ActiveButton">Show Search Enable</div>
                 </mat-slide-toggle>
                 <mat-slide-toggle class="example-margin" color="primary"
                                   [disabled]="false" [checked]="true"
                                   (change)="toggleSolutions($event)">
                   <div class="ActiveButton">Show Active</div>
                 </mat-slide-toggle>
               </div>
                <div>
                  <button class="btn btn-create  theme-btn-color text-white" (click)="clearFilter()">
                    <i class="fas fa-redo me-2"></i>
                    Reset Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <table class="table table-striped ">
            <thead class="tableview theme-bg-color">
            <tr>
              <th style="width: 10%" *ngFor="let header of tableHeader">{{ header }}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container>
              <tr search-filter [searchFields]="searchFieldName" [filters]="filters"
                  (filtersChanged)="onFiltersChanged($event)" (dateTypeChanged)="dateTypeChanged($event)"
                  (resetFilters)="clearFilter()">
              </tr>
            </ng-container>

            <tr *ngFor="let data of solutionData">
              <td class="theme-text-color fw-bold cursor-p"
                  (click)="navigateToEditSolution(data.id)"
              >{{ data?.solutionId }}
              </td>
              <td>{{ data?.name }}</td>
              <td>{{ data?.solutionInfo?.vendor?.vendorName }}</td>
              <td>
                <ng-container
                  *ngIf="formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.displayed.length > 0">
                  <span *ngFor="let item of formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.displayed">
                    {{ item }}
                    <ng-container
                      *ngIf="item !== formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.displayed[formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.displayed.length - 1]">, </ng-container>
                  </span>
                  <span *ngIf="formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.tooltip.length > 0"
                        [matTooltip]="formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.tooltip.join(', ')"
                        matTooltipPosition="above">
                    ...more
                  </span>
                </ng-container>
                <ng-container *ngIf="!formatIndustryVertical(data?.solutionInfo?.industryVerticals)?.displayed.length">
                  --
                </ng-container>
              </td>
              <td>{{data.solutionTechnology?.solutionType}}</td>
              <td>
                <ng-container
                  *ngIf="formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.displayed.length > 0">
                  <span *ngFor="let item of formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.displayed">
                    {{ item }}
                    <ng-container
                      *ngIf="item !== formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.displayed[formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.displayed.length - 1]">, </ng-container>
                  </span>
                  <span *ngIf="formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.tooltip.length > 0"
                        [matTooltip]="formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.tooltip.join(', ')"
                        matTooltipPosition="above">
                    ...more
                  </span>
                </ng-container>
                <ng-container *ngIf="!formatTechStackUsed(data?.solutionTechnology?.techStackUsed)?.displayed.length">
                  --
                </ng-container>
              </td>
              <td>
                {{ data?.solutionTechnology?.demoReady === true ? 'Yes' : (data?.solutionTechnology?.demoReady === false ? 'No' : '--') }}
              </td>
              <td>
                <span *ngIf="getTruncatedDescription(data?.solutionInfo?.description).tooltip">
                  <span [matTooltip]="getTruncatedDescription(data?.solutionInfo?.description).tooltip"
                        matTooltipPosition="above">
                    {{ getTruncatedDescription(data?.solutionInfo?.description).displayed }}
                  </span>
                </span>
                <span *ngIf="!getTruncatedDescription(data?.solutionInfo?.description).tooltip">
                  {{ getTruncatedDescription(data?.solutionInfo?.description).displayed }}
                </span>
              </td>
              <td style="width: 13%">
                <div class="d-flex flex-column">
                  <span *ngIf="data.updatedDate" style="font-size: 13px;">
                  Updated On {{ data.updatedDate | date: 'dd MMM y hh:mm a' }}
                </span>
                  <span class="p-0 m-0" *ngIf="data.createdDate" style="font-size: 13px;">
                      Created On {{ data.createdDate | date: 'dd MMM y hh:mm a' }}
                </span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!showLoader && solutionData.length == 0" class="d-flex justify-content-center mt-8">
          <div>
            <img src="assets/images.png" alt=""><br>
            <h4> No Result Found</h4>
          </div>
        </div>
        <mat-paginator [length]="totalElements"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10,12,25,50,100]"
                       [hidden]="totalElements <= 12"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
