import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Customer, jobIdDt, Skill, userDT} from "../../../shared/interfaces";
import {FormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Observable, ReplaySubject} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {ApiService} from "../../../services/api.service";
import {DatePipe} from "@angular/common";
import {debounce} from "lodash";
import {MatPaginator} from "@angular/material/paginator";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: '[search-filter]',
  templateUrl: './advance-search-filters.component.html',
  styleUrls: ['./advance-search-filters.component.css']
})
export class AdvanceSearchFiltersComponent implements OnInit {
  @Input() searchFields: string[];
  @Input() filters: any;
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateTypeChanged = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter<any>();
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChildren('dropdownExperience, dropdownSkills, dropdownConsultantStatus, dropdownLocationPreference,dropdownDate,dropdownEmailSent,dropdownAssignedSPOC' +
    ',DropdownPostingDate,dropdownClientJobStatus,dropdownExperienceYear,dropdownTechStack,dropdownSolutionType,dropdownIndustryVertical' +
    ',dropdownInformationTag,solutionTypes,demoReady,dropdownNoticePeriod,verificationStatusRequests') dropdowns!: QueryList<ElementRef>;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  selectedSkill: Skill[] = [];
  skillControl = new FormControl('');
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredSkills: Observable<Skill[]>;
  skills: any[] = [];
  disabledSkills: Skill[] = [];
  skillsRes: Skill[] = [];
  selectedFromDate: string = '';
  selectedToDate: string = '';
  minEndDate: string | null;
  isCreatedDate: boolean = false;
  selectedItemsLocationPreference: string[] = [];
  selectedItemsNoticePeriod: string[] = [];
  locationPreferenceOptions: string[] = ['REMOTE', 'HYBRID', 'ONSITE'];
  noticePeriodOptions = ['ZERO_DAYS', 'SEVEN_DAYS', 'FIFTEEN_DAYS', 'THIRTY_DAYS', 'THIRTY_PLUS_DAYS'];
  statusWebCustomerOptions = ['PENDING', 'SHORTLISTED ']
  selectedItemsWebCustomer: string[] = [];
  emailSentOptions: string[] = ['Yes', 'No'];
  selectedEmailOption: string[] = [];
  assigneeList: userDT[] = [];
  selectedItemsConsultantStatus: string[] = [];
  selectedItemsCustomersStatus: string[] = [];
  consultantStatusOptions: string[] = ['IN_PROGRESS', 'ON_HOLD', 'COMPLETED'];
  customersStatusOptions: string[] =['UNVERIFIED', 'INPROGRESS', 'ONHOLD'];
  selecteddemoReady: string[] = []
  selectedItemsClientJobStatus: string[] = [];
  clientJobStatusOptions: string[] = ['SAVED', 'POSTED'];
  selectedAssignedSpocIds: number[] = [];
  assignedSpocControl = new FormControl('');
  assignedSpocControlMultiSelect = new FormControl('');
  searchState: boolean = false;
  selectedItemsExperience: { min: number, max: number }[] = [];
  experienceOptions: { min: number, max: number, label: string }[] = [
    {min: 0, max: 1, label: '0-1 years'},
    {min: 1, max: 3, label: '1-3 years'},
    {min: 3, max: 5, label: '3-5 years'},
    {min: 5, max: 8, label: '5-8 years'},
    {min: 8, max: 10, label: '8-10 years'},
    {min: 10, max: Infinity, label: '10 years+'}
  ];
  selectedSolutionOptions: { value: string, label: string }[] = [
    {value: 'SAAS_MODEL', label: 'SAAS Model'},
    {value: 'DESKTOP_BASED', label: 'Desktop Based'},
    {value: 'WEB_BASED', label: 'Web Based'},
    {value: 'CLOUD_BASED', label: 'Cloud Based'}
  ];
  selectedSolutionTypes: any[] = [];
  demoReadyOptions: string[] = ['Yes', 'No'];
  selectedIndustryVertical: any[] = [];
  selectedIndustryVerticalId: any[] = [];
  verticalControl = new FormControl();
  filteredVerticals: Observable<any[]>;
  industryVerticalOptions: any[] = [];
  informationTagOptions: any[] = [];
  selectedInformationTag: any[] = [];
  informationTagControl = new FormControl();
  filteredTag: Observable<any[]>;
  public filteredAssignSpocMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    private service: ApiService,
    private datePipe: DatePipe,
    private resetFilter: ResetFiltersService
  ) {
  }

  ngOnInit(): void {
    this.getSkillType();
    this.getAllIndustryVerticals();
    this.getAllInformationTag();
    this.getCustomerClients();
    this.resetFilter.clickedFilter.subscribe((value: boolean) => {
      if (value) {
        this.resetAllFilter();
      }
    });
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({...skill, disabled: true});
    if (!this.filters.skills?.includes(skill.id)) {
      this.filters.skills?.push(skill.id);
      this.filtersChanged.emit(this.filters);
    }
    if (!this.filters.techStacks?.includes(skill.id)) {
      this.filters.techStacks?.push(skill.id);
      this.filtersChanged.emit(this.filters);
    }
    this.filtersChanged.emit(this.filters)
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
      const skillIndex = this.filters.skills.indexOf(skill.id);
      if (skillIndex >= 0) {
        this.filters.skills.splice(skillIndex, 1);
        this.filtersChanged.emit(this.filters);
      }
    }
  }

  disableSkill(skill: any): boolean {
    return this.selectedSkill.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  closeAllDropdowns() {
    this.dropdowns.forEach(dropdown => {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdowns-menu');
      dropdownMenu.style.display = 'none';
    });
  }

  toggleDropdown(dropdownName: string) {
    const dropdown = this.dropdowns.find(dropdown => dropdown.nativeElement.id === dropdownName);
    if (dropdown) {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdowns-menu');
      const isOpen = dropdownMenu.style.display === 'block';
      this.closeAllDropdowns();
      if (!isOpen) {
        dropdownMenu.style.display = 'block';
      }
    }
  }

  resetDateFields() {
    this.selectedFromDate = '';
    this.filters.dateSearchCriteria.from = '';
    this.selectedToDate = '';
    this.filters.dateSearchCriteria.to = '';
  }

  handleDateToggleChange(event: any) {
    this.isCreatedDate = event.target.checked;
    this.resetDateFields();
    this.dateTypeChanged.emit(this.isCreatedDate);
  }

  onFromDateChange(event: any): void {
    const date = event.value;
    this.selectedFromDate = this.formatDate(date);
    this.filters.dateSearchCriteria.from = this.selectedFromDate;
    this.filtersChanged.emit(this.filters);
  }

  onFromDateChangeSingle(event: any): void {
    const date = event.value;
    this.selectedFromDate = this.formatDate(date);
    this.filters.from = this.selectedFromDate;
    this.filtersChanged.emit(this.filters);
  }

  onToDateChangeSingle(event: any): void {
    const date = event.value;
    this.selectedToDate = this.formatDate(date);
    this.filters.to = this.selectedToDate;
    this.filtersChanged.emit(this.filters);
  }

  onToDateChange(event: any): void {
    const date = event.value;
    this.selectedToDate = this.formatDate(date);
    this.filters.dateSearchCriteria.to = this.selectedToDate;
    this.filtersChanged.emit(this.filters);
  }

  private formatDate(date: Date | null): string {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') || '' : '';
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.dropdowns.some(dropdown => dropdown.nativeElement.contains(event.target));
    const clickedOnDatePicker = (event.target as Element).closest('mat-datepicker') !== null;
    const clickedOnDatePickerOrDescendant = (event.target as Element).closest('.mat-datepicker,.mat-datepicker-popup') !== null;
    if (!clickedInside && !clickedOnDatePicker && !clickedOnDatePickerOrDescendant) {
      this.closeAllDropdowns();
    }
  }

  updateEndDateMinDate() {
    if (this.selectedFromDate) {
      const startDate = new Date(this.selectedFromDate);
      startDate.setDate(startDate.getDate() + 1);
      this.minEndDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    } else {
      this.minEndDate = null;
    }
  }

  onChangeSearch = debounce((value: string, fieldName: string) => {
    if (fieldName === '') {
      return;
    } else if (fieldName === 'dateSearchCriteria') {
      this.filters.dateSearchCriteria.from = this.datePipe.transform(value, 'yyyy-MM-dd') ?? '';
      this.filters.dateSearchCriteria.to = this.datePipe.transform(value, 'yyyy-MM-dd') ?? '';
    } else {
      this.filters[fieldName] = value ?? '';
    }
    const fromDate = this.filters?.dateSearchCriteria?.from;
    const toDate = this.filters?.dateSearchCriteria?.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    if (datesValid || (fieldName !== 'fromDate' && fieldName !== 'toDate')) {
      this.filtersChanged.emit(this.filters);
    }
  }, 600);

  getCustomerClients() {
    this.service.getSpocList().subscribe((res: any) => {
      this.assigneeList = res;
      this.filteredAssignSpocMulti.next(this.assigneeList);
    });
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectedAssignedSpocIds = event.value;
    if (this.filters.assigneeId) {
      this.filters.assigneeId = event.value;
    } else {
      this.filters.spocIds = event.value;
    }
    this.filtersChanged.emit(this.filters);
  }

  onSearchInputChange(searchTerm: string) {
    if (searchTerm === null) {
      return;
    }
    const searchTermLower = searchTerm.toLowerCase();
    this.filteredAssignSpocMulti.next(
      this.assigneeList.filter((spoc) =>
        spoc.firstName?.toLowerCase()?.includes(searchTermLower)
      )
    );
  }

  resetAllFilter() {
    for (const key in this.filters) {
      if (this.filters.hasOwnProperty(key) && this.searchFields.includes(key)) {
        switch (typeof this.filters[key]) {
          case 'object':
            if (Array.isArray(this.filters[key])) {
              this.filters[key] = [];
            } else {
              for (const subKey in this.filters[key]) {
                if (this.filters[key].hasOwnProperty(subKey)) {
                  this.filters[key][subKey] = '';
                }
              }
            }
            break;
          case 'string':
            this.filters[key] = '';
            break;
          case 'boolean':
            this.filters[key] = false;
            break;
          default:
            this.filters[key] = null;
        }
      }
    }

    if (this.searchFields.includes('experience')) {
      this.selectedItemsExperience = [];
    }
    if (this.searchFields.includes('skills') || this.searchFields.includes('primarySkills')) {
      this.selectedSkill = [];
      this.skillControl.reset();
    }
    if (this.searchFields.includes('noticePeriod')) {
      this.selectedItemsNoticePeriod = [];
    }
    if (this.searchFields.includes('status') || this.searchFields.includes('webCandidateStatus')) {
      this.selectedItemsConsultantStatus = [];
      this.selectedItemsWebCustomer = [];
    }
    if (this.searchFields.includes('dateSearchCriteria')) {
      this.selectedFromDate = '';
      this.selectedToDate = '';
      this.isCreatedDate = false;
      if (!this.filters.dateSearchCriteria) {
        this.filters.dateSearchCriteria = {};
      }
      this.filters.dateSearchCriteria.from = '';
      this.filters.dateSearchCriteria.to = '';
    }
    if (this.searchFields.includes('locationsPref')) {
      this.selectedItemsLocationPreference = [];
    }
    if (this.searchFields.includes('emailSentStatus')) {
      this.selectedEmailOption = [];
    }
    if (this.searchFields.includes('assignee') || this.searchFields.includes('spocIds')) {
      this.selectedAssignedSpocIds = [];
      this.assignedSpocControl.setValue([]);
      this.assignedSpocControlMultiSelect.reset();
    }
    if (this.searchFields.includes('postingDate')) {
      this.selectedFromDate = '';
      this.selectedToDate = '';
    }
    if (this.searchFields.includes('ClientStatus')) {
      this.selectedItemsClientJobStatus = [];
    }
    if (this.searchFields.includes('ExperienceYear')) {
      this.selectedItemsExperience = [];
      delete this.filters.experienceYears;
    }
    if (this.searchFields.includes('industryVertical')) {
      this.selectedIndustryVertical = [];
      this.selectedIndustryVerticalId = [];
      this.verticalControl.reset();
    }
    if (this.searchFields.includes('demoReady')) {
      this.selecteddemoReady = [];
    }
    if (this.searchFields.includes('solutionTypes')) {
      this.selectedSolutionTypes = [];
    }
    if (this.searchFields.includes('techStack') || this.searchFields.includes('techStacks')) {
      this.selectedSkill = [];
      this.skillControl.reset();
    }
    if (this.searchFields.includes('InformationTag')) {
      this.selectedInformationTag = [];
    }
    if (this.searchFields.includes('statusWebCustomer')) {
      this.selectedItemsWebCustomer = [];
    }
    if (this.searchFields.includes('dropdownNoticePeriod')) {
      this.selectedItemsNoticePeriod = [];
    }
    if(this.searchFields.includes('verificationStatusRequests')){
      this.selectedItemsCustomersStatus = []
    }
    this.filtersChanged.emit(this.filters);
  }

  getAllIndustryVerticals(): void {
    this.service.getIndustryVerticals().then((res: any) => {
      this.industryVerticalOptions = res;
      this.filteredVerticals = this.verticalControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
  }

  removeVertical(vertical: any) {
    const index = this.selectedIndustryVertical.indexOf(vertical);
    if (index >= 0) {
      this.selectedIndustryVertical.splice(index, 1);
      this.selectedIndustryVerticalId.splice(index, 1);
      const verticalIndex = this.filters.industryVertical.indexOf(vertical.id);
      if (verticalIndex >= 0) {
        this.filters.industryVertical.splice(verticalIndex, 1);
        this.filtersChanged.emit(this.filters);
      }
    }
  }

  selectVertical(event: any) {
    const selectedVertical = event.option.value;
    if (!this.selectedIndustryVertical.includes(selectedVertical)) {
      this.selectedIndustryVertical.push(selectedVertical);
      this.selectedIndustryVerticalId.push(selectedVertical.id);
      this.filters.industryVertical?.push(selectedVertical.id);
      this.filtersChanged.emit(this.filters);
    }
    this.verticalControl.setValue('');
  }

  disableVertical(vertical: any): boolean {
    return this.selectedIndustryVertical.includes(vertical);
  }

  private _filter(value: any): any[] {
    const filterValue = typeof value === 'string' ? value?.toLowerCase() : (value?.name ? value?.name?.toLowerCase() : '');
    return this.industryVerticalOptions.filter(vertical =>
      vertical.name.toLowerCase().includes(filterValue)
    );
  }

  getAllInformationTag(): void {
    this.service.getInformationTag().then((res: any) => {
      this.informationTagOptions = res;
      this.filteredTag = this.informationTagControl.valueChanges.pipe(
        startWith(''),
        map(value => this.FilterInformationTag(value))
      );
    });
  }

  private FilterInformationTag(value: string): any[] {
    const filterValue = (value && typeof value === 'string') ? value.toLowerCase() : '';
    return this.informationTagOptions.filter(option => option.tagName.toLowerCase().includes(filterValue));
  }

  isSelectedExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]): boolean {
    return selectedItems.some(item => item.min === option.min && item.max === option.max);
  }

  toggleSelectionExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]) {
    const index = selectedItems.findIndex(item => item.min === option.min && item.max === option.max);
    if (index > -1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems.push({min: option.min, max: option.max});
    }
    this.filters.experienceYears = selectedItems;
    this.filtersChanged.emit(this.filters);
  }

  handleSelection(type: string, option: any, selectedItems: any[], filterKey: string, singleSelect: boolean = false): void {
    if (singleSelect) {
      selectedItems.length = 0;
    }
    if (typeof option === 'object') {
      const index = selectedItems.findIndex(item => JSON.stringify(item) === JSON.stringify(option));
      if (index > -1) {
        selectedItems.splice(index, 1);
      } else {
        selectedItems.push(option);
      }
    } else {
      if (selectedItems.includes(option)) {
        selectedItems.splice(selectedItems.indexOf(option), 1);
      } else {
        selectedItems.push(option);
      }
    }
    this.filters[filterKey] = selectedItems;
    this.filtersChanged.emit(this.filters);
  }

  isSelected(type: string, option: any, selectedItems: any[]): boolean {
    if (typeof option === 'object') {
      return selectedItems.some(item => JSON.stringify(item) === JSON.stringify(option));
    } else {
      return selectedItems.includes(option);
    }
  }

}
