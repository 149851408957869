<div class="content-wrapper">
  <div class="pb-3 ps-3 pe-3">
    <span style="font-weight:500; font-size: 20px">{{this.currentRoute === 'candidate' ? 'Candidate Information' : 'Customer Information'}}</span>
  </div>
  <form [formGroup]="candidateForm" class="ps-3 pe-3">
    <div class="form-row">
      <div *ngFor="let field of formFields"
           [ngClass]="{
           'col-md-12': field.label === 'LinkedIn URL' && this.currentRoute === 'candidate',
           'col-md-4': field.label !== 'LinkedIn URL' || this.currentRoute === 'customer'
           }">
        <mat-form-field *ngIf="field.label === 'Experience Years' || field.label === 'Experience Months'"
                        appearance="outline" class="w-100 ">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select [formControlName]="field.formControlName">
            <mat-option *ngFor="let option of (field.label === 'Experience Years' ? experienceYears : experienceMonths)"
                        [value]="option">{{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="this.currentRoute === 'candidate'">
          <mat-form-field *ngIf="field.label !== 'Experience Years' && field.label !== 'Experience Months' && field.label !== 'LinkedIn URL'"
                          appearance="outline" class="w-100">
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [type]="field.type"
              [formControlName]="field.formControlName"
              [required]="field.required">
          </mat-form-field>
        </div>

        <div *ngIf="this.currentRoute === 'customer'">
          <mat-form-field *ngIf="field.label !== 'Experience Years' && field.label !== 'Experience Months'"
                          appearance="outline" class="w-100">
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [type]="field.type"
              [formControlName]="field.formControlName"
              [required]="field.required">
          </mat-form-field>
        </div>

        <div class=" mb-4 " *ngIf="field.label == 'LinkedIn URL' && this.currentRoute === 'candidate'">
          <div *ngIf="updateURL" class="">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [type]="field.type"
                [formControlName]="field.formControlName"
                [required]="field.required">
            </mat-form-field>
          </div>
          <div class="d-flex justify-content-between" *ngIf="!updateURL">
            <div class="ms-2">
              <mat-label> LinkedIn URL : </mat-label>
              <span (click)="goToLink(this.linkedInURL)"><a style="color: blue; font-style: italic; text-decoration: underline;cursor: pointer" >{{this.linkedInURL}}</a></span>
            </div>
            <div>
              <app-button [width]="'250px'" [height]="'40px'" type="primary" (buttonClick)="updateUrl()"> Change LinkedIn URL </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="currentLocation">
      <div class="pb-2">
        <span style="font-weight:500; font-size: 16px">{{this.currentRoute === 'candidate' ? 'Current Location':'Address Information'}}</span>
      </div>
      <div class="form-row">
        <div class="col-md-8">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Street Address</mat-label>
            <input matInput placeholder="Street Address" formControlName="streetAddress">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>PinCode</mat-label>
            <input matInput placeholder="PinCode" formControlName="postalCode" maxlength="6">
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div formGroupName="country" class="col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Country</mat-label>
            <mat-select [formControl]="countryControl" [value]="selectedCountry" [compareWith]="compareFunction"
                        (valueChange)="selectCountry($event)" placeholder="Country">
              <mat-option>
                <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                       [placeholderLabel]="'Search Country Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="state" class="col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>State</mat-label>
            <mat-select [formControl]="stateControl" [value]="selectedState" [compareWith]="compareFunction"
                        (valueChange)="selectState($event)" placeholder="State"
                        [disabled]="!selectedCountry">
              <mat-option>
                <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                       [placeholderLabel]="'Search State Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="city" class="col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>City</mat-label>
            <mat-select [formControl]="cityControl" [value]="selectedCity" (valueChange)="selectCity($event)"
                        [compareWith]="compareFunction"
                        placeholder="City" [disabled]="!selectedState">
              <mat-option>
                <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                       [placeholderLabel]="'Search City Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="this.currentRoute === 'candidate'">
      <div class="col-md-12 w-100">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" rows="1" placeholder="Add notes" [disabled]="true"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <app-button [width]="'150px'" [height]="'40px'" type="primary" (buttonClick)="onSubmit()" [isLoading]="loading">Save Details</app-button>
    </div>
  </form>
</div>
