<div class="mx-3">
  <div class="row align-items-center card-shadow my-4 p-4 pb-2">
    <div class="col-md-6 border-right">
      <div class="form-group">
        <h3 class="font-weight-bold">Task Status: </h3>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="status" (selectionChange)="onStatusChange($event)">
            <mat-option *ngFor="let status of statusList" [value]="status" class="dropItems"
                        (click)="$event.stopImmediatePropagation()">
              <a class="dropdown-item">
                {{ status }}
              </a>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group d-flex justify-content-end">
        <div>{{ errorState }}</div>
        <div *ngIf="confirmButtonEnable && status!='ON_HOLD'" class="info-action-btn" (click)="confirmButton()">
          Confirm changes
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <h3 class="font-weight-bold">Task Information:</h3>
        <div class="">
          <div class="subHeadings-labels d-flex align-items-baseline">Assigned Information :
            <div *ngIf="!managerAdd"
                 class="subHeadings ms-2">{{ myTaskData?.assignee?.firstName + ' ' + myTaskData?.assignee?.lastName }}
            </div>
            <div *ngIf="managerAdd" class="form-group ms-2 mb-0">
              <mat-form-field appearance="outline" class="w-auto border-red">
                <mat-label>Assignee</mat-label>
                <mat-select [(ngModel)]="assigne" (selectionChange)="onAssigneeChange($event)">
                  <mat-option *ngFor="let memeber of memebersList" [value]="memeber" class="dropItems"
                              (click)="$event.stopImmediatePropagation()">
                    <a class="dropdown-item">
                      {{ memeber.role + ' | ' + memeber.firstName + ' ' + memeber.lastName }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="role=='ADMIN' || role=='SALES_HEAD'"
             class="d-flex justify-content-end">
          <a *ngIf="!managerAdd" class="info-action-btn change-manager-btn text-decoration-underline"
             (click)="onChangeManager()">Change Manager</a>
          <div *ngIf="managerAdd" class="info-action-btn cancel-btn text-decoration-underline" (click)="cancel()">
            Cancel
          </div>
        </div>
        <div *ngIf="role!='ADMIN' && role!='SALES_HEAD'" class="col">
          <h6>{{ myTaskData?.assignee?.firstName + ' ' + myTaskData?.assignee?.lastName }}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
