<button
  [ngClass]="getButtonClass()"
  [disabled]="isDisabled || isLoading"
  [ngStyle]="{ 'width': width, 'height': height }"
(click)="handleClick()">
<div *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status">
  <span class="sr-only">Loading...</span>
</div>
<ng-content *ngIf="!isLoading"></ng-content>
</button>
