<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper">
  <div class="member_wrapper" mat-dialog-title>
    <div class="modal-inner-content">
      <div class="d-flex align-items-center justify-content-between dialogBackgroundColor p-2">
        <div>
          <h5 class="content-header">
            Assign Customer
          </h5>
        </div>
        <div type="button" (click)="closeDialog()" class="cross_icon">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div>
    <form [formGroup]="customerContactForm">
      <div class="ps-3 pe-3">
        <mat-form-field appearance="outline" class="w-100 border-red skills_drop">
          <mat-label>Customers</mat-label>
          <input
            matInput
            placeholder="Select a customer..."
            [formControl]="customerControl"
            [matAutocomplete]="auto"
            #autoTrigger="matAutocompleteTrigger"
            (click)="openAutocomplete(autoTrigger)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCustomer($event)">
            <mat-option *ngFor="let customer of filteredCustomer | async" [value]="customer">
              {{ customer.companyName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredCustomer|async)?.length == 0" class="dropdown-skills position-relative mb-3">
          <ul>
            <li class="li_disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold">
              <div class="d-flex justify-content-between align-items-center" (click)="addCustomer()">
                <span>Add Customer</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="ps-3 pe-3">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Assigned SPOC</mat-label>
          <input #SPOCAutoCompleter type="text" matInput placeholder="Assigned SPOC" [formControl]="spocControl" [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)" [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="me-3">
          <app-button [width]="'100px'" [height]="'35px'" type="text" (buttonClick)="closeDialog()">
            Cancel
          </app-button>
        </div>
        <div class="me-3">
          <app-button [width]="'100px'" [height]="'35px'" type="primary" (buttonClick)="assignCustomer()">
            Assign
          </app-button>
        </div>
      </div>
    </form>
  </div>
</div>
